import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AppContext from 'containers/AppContext'
import NavigationItem from 'containers/Page/NavigationItem'
import AppBar from 'components/AppBar'
import Svg from 'components/Svg'
import Link from 'components/Link'
import withStyles from 'components/styles/withStyles'
import Typography from 'components/Typography'

export const styles = theme => ({
  root: {},
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    '& strong': {
      textTransform: 'uppercase',
    },
  },
  navItem: {
    ...theme.typography.h5,
    fontSize: theme.typography.pxToRem(20),
    textTransform: 'uppercase',
    marginRight: theme.utils.spacing(10),
    '&:not(:last-child)': {},
  },
  logoText: {
    '& p': {
      margin: 0,
      lineHeight: 1.2,
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing.unit * 2,
    },
  },
})

const DesktopToolbar = props => {
  const { classes, className, ...other } = props
  const { mainMenu } = useContext(AppContext)

  const logoText = '<p>Collective Intelligence <strong>Model</strong></p>'
  const logoTextRender = logoText.split(' ').join('<br/> ')

  console.log('mainMenu', mainMenu)

  return (
    <AppBar
      className={classnames(classes.root, className)}
      {...other}
      color="inherit"
    >
      <div className={classes.grow}>
        <Link href="/" className={classes.logo}>
          <Svg.CICSLogoAnimated width="90" />

          <Typography
            className={classes.logoText}
            component="div"
            dangerouslySetInnerHTML={{
              __html: logoTextRender,
            }}
          />
        </Link>
      </div>
      <div>
        {mainMenu.map(item => (
          <NavigationItem
            className={classes.navItem}
            key={`desktopNavItem-${item.id}`}
            {...item}
          />
        ))}
      </div>
    </AppBar>
  )
}

DesktopToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

DesktopToolbar.uiName = 'DesktopToolbar'

export default withStyles(styles)(DesktopToolbar)
