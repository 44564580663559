import { promisify } from 'util'
import redis from 'redis'

export default class RedisCache {
  static fromUrl(url) {
    return new RedisCache({ url: url.format() })
  }

  constructor(options) {
    this.client = redis.createClient(options)
    this.getAsync = promisify(this.client.get).bind(this.client)
    this.setAsync = promisify(this.client.set).bind(this.client)

    this.client.on('error', err => {
      console.log('Redis client error: %s.', err)
    })
  }

  async get(key) {
    if (!this.client.connected) {
      return null
    }
    return this.getAsync(key).then(JSON.parse)
  }

  async set(key, value, ttl = 500) {
    if (!this.client.connected) {
      return Promise.resolve()
    }
    return this.setAsync(key, JSON.stringify(value), 'EX', ttl)
  }

  async load(_data) {
    return Promise.resolve()
  }

  async dump() {
    return {}
  }
}
