import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { accent, grey, purple, blue, common, turquoise } from '../colors'

export { keys } from '@material-ui/core/styles/createBreakpoints'

// A theme with custom primary and secondary color.
// It's optional.
export default () => {
  const htmlFontSize = 16
  const coef = htmlFontSize / 16
  const baseUnit = 8
  const pxToRem = value => `${(value / htmlFontSize) * coef}rem`
  const fontWeightLight = 300
  const fontWeightRegular = 400
  const fontWeightMedium = 500
  const fontWeightBold = 700
  const fontFamilyPrimary = '"Brandon", "Helvetica", "Arial", sans-serif'

  const breakpoints = createBreakpoints({})

  return createMuiTheme({
    palette: {
      primary: {
        light: purple[300],
        main: purple[500],
        dark: purple[700],
      },
      secondary: {
        light: grey[300],
        main: grey[500],
        dark: grey[700],
      },
      background: {
        default: common.white,
      },
      tertiary: common.white,
      black: common.black,
      white: common.white,
      purple,
      blue,
      grey,
      turquoise,
      accent,
    },
    breakpoints,
    typography: {
      useNextVariants: true,
      fontFamily: fontFamilyPrimary,
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightBold,
      pxToRem,
      h1: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(36),
        fontWeight: fontWeightLight,
        lineHeight: 1.29,
        color: common.black,
        [breakpoints.up('md')]: {
          fontSize: pxToRem(48),
          lineHeight: 1.29,
        },
      },
      h2: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(24),
        fontWeight: fontWeightLight,
        lineHeight: 1.27,
        color: common.black,
        [breakpoints.up('md')]: {
          fontSize: pxToRem(36),
          lineHeight: 1.29,
        },
      },
      h3: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(16),
        fontWeight: fontWeightMedium,
        lineHeight: 1.4,
        color: purple[500],
        textTransform: 'uppercase',
      },
      h4: {
        fontSize: pxToRem(18),
        fontWeight: fontWeightLight,
        lineHeight: 1.1,
        color: common.black,
        [breakpoints.up('md')]: {
          fontSize: pxToRem(20),
          lineHeight: 1.5,
        },
      },
      h5: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(16),
        fontWeight: fontWeightMedium,
        lineHeight: 1.4,
        color: common.black,
        textTransform: 'uppercase',
      },
      h6: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(16),
        fontWeight: fontWeightRegular,
        lineHeight: 1.4,
        color: common.black,
        textTransform: 'uppercase',
      },
      body1: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(18),
        fontWeight: fontWeightLight,
        lineHeight: 1.5,
        color: common.black,
      },
      body2: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(16),
        fontWeight: fontWeightLight,
        lineHeight: 1.5,
        color: common.black,
      },
      button: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(16),
        fontWeight: fontWeightMedium,
        lineHeight: 1.4,
        color: common.white,
        textTransform: 'uppercase',
      },
      articleBody: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(16),
        fontWeight: fontWeightLight,
        lineHeight: 1.5,
        color: common.black,
      },
      articleH2: {
        fontFamily: fontFamilyPrimary,
        fontSize: pxToRem(24),
        fontWeight: fontWeightMedium,
        lineHeight: 'normal',
        color: common.black,
      },
    },
    shape: {
      borderRadius: 0,
    },
    utils: {
      unit: baseUnit,
      spacing: value => `${baseUnit * value}px`,
    },
  })
}
