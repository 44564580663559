import React, { useContext } from 'react'
import PropTypes from 'prop-types'
// import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import Hidden from '@material-ui/core/Hidden'
import Sidebar from './Sidebar'
import MobileToolbar from './MobileToolbar'
import DesktopToolbar from './DesktopToolbar'
import Footer from './Footer'
import withStyles from 'components/styles/withStyles'
import CookieBar from 'components/CookieBar'
import AppContext from 'containers/AppContext'

/**
 * Purpose:
 *
 * Wrapper for UI components that are shared between routes
 * e.g. a Header or a Footer
 */
export const styles = theme => ({
  root: {
    flexGrow: 1,
    '& main': {
      margin: '0 auto',
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  cookieBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 2,
  },
})
const Page = ({ children, classes }) => {
  const { settings } = useContext(AppContext)

  return (
    <div className={classes.root}>
      <CookieBar className={classes.cookieBar} text={settings.cookieBar} />
      <Hidden mdUp>
        <MobileToolbar />
        <Sidebar />
      </Hidden>

      <Hidden smDown initialWidth="md">
        <DesktopToolbar />
      </Hidden>

      <main>{children}</main>

      <Footer />
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
}

Page.uiName = 'Page'

export default withStyles(styles)(Page)
