import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'
import Input from 'components/Input'
import Typography from 'components/Typography'
import Button from 'components/Button'
import Svg from 'components/Svg'
import Checkbox from 'components/Checkbox'
import FormControlLabel from 'components/FormControlLabel'
import Link from 'components/Link'
import { setDisplayName } from 'recompose'

export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.typography.unit,
    },
  },
  inner: {
    width: '100%',
    display: 'flex',
    '$blocked &': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& input': {
          textAlign: 'center',
        },
      },
    },
  },
  blocked: {},
  input: {
    ...theme.typography.h6,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    backgroundColor: theme.palette.white,
    border: 'none',
    color: theme.palette.secondary.main,
    paddingLeft: theme.utils.spacing(3),
    '& input::placeholder': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'uppercase',
      opacity: 1,
    },
  },
  submit: {
    ...theme.typography.button,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('md')]: {
      height: 57,
      padding: '0  10px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 57,
      height: 57,
    },
  },
  error: {
    padding: theme.spacing.unit,
    color: theme.palette.secondary.main,
  },
  check: {
    marginLeft: 0,
    marginRight: 0,
    color: theme.palette.secondary.main,
    '& span': {
      color: theme.palette.secondary.main,
    },
  },
  labelLink: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    transition: 'border 0.1s linear',
    '&:hover': {
      borderBottomColor: theme.palette.accent.purple,
    },
  },
  underline: {
    '&::before, &:hover::before': {
      border: 'none !important',
    },
    '&::after': {
      borderBottomColor: theme.palette.black,
    },
  },
  icon: {
    fill: theme.palette.white,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  submitText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  checkbox: {
    '& svg': {
      fill: theme.palette.primary.main,
      transition: 'fill 0.1s linear',
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.accent.purple,
      },
    },
  },
  submitted: {
    background: `${theme.palette.accent.purple} !important`,
  },
  activeInput: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: `${theme.palette.accent.purple} !important`,
    },
  },
  submittedIcon: {
    fill: theme.palette.white,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing.unit,
    },
  },
})

function Signup(props) {
  const {
    children,
    classes,
    className,
    onSubmit,
    submitText,
    checkboxText,
    fieldErrorText,
    errorText,
    checkErrorText,
    link,
    inputProps: { inputClassName, ...inputProps } = {},

    ...other
  } = props

  const [value, setValue] = useState('')
  const [canProceed, setCanProceed] = useState(false)
  const [hasError, setError] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [activeInput, setActiveInput] = useState(false)
  const inputRef = useRef()

  const error =
    (canProceed && value === '' && fieldErrorText) ||
    (canProceed && errorText) ||
    checkErrorText

  const btnClassName = classnames(classes.submit, {
    [classes.submitted]: submitted,
    [classes.activeInput]: activeInput,
  })

  const checkboxLabel = (
    <>
      {checkboxText}
      {link && (
        <Link className={classes.labelLink} href={link.href}>
          {link.title}
        </Link>
      )}
    </>
  )

  const btnLabel = (
    <>
      {submitted && (
        <>
          <Svg.SignupCheck className={classes.submittedIcon} width="24" />
          <span className={classes.submitText}>Sent</span>
        </>
      )}
      {!submitted && (
        <>
          <span className={classes.submitText}>{submitText}</span>
          <Svg.Email width="24" className={classes.icon} />
        </>
      )}
    </>
  )

  const inputOnFocus = ({ target }) => {
    setError(false)
    setActiveInput(true)
  }

  const inputOnBlur = ({ target }) => {
    if (target.value !== '') {
      setActiveInput(true)
    } else {
      setActiveInput(false)
    }
  }

  const submittedBtn = () => {
    setSubmitted(true)
    setTimeout(() => setSubmitted(false), 2000)
  }

  const handleCheckbox = ({ target: { checked } }) => {
    setCanProceed(checked)

    if (hasError && checked) {
      setError(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (canProceed) {
      const response = props.onSubmit(value)
      response.then(res => {
        if (res && !res.ok) {
          setError(true)
        } else {
          setError(false)
          submittedBtn()
          setValue('')
          setActiveInput(false)
        }
      })
    } else {
      setError(true)
    }
  }

  return (
    <form
      className={classnames(classes.root, className)}
      onSubmit={handleSubmit}
      action=""
      {...other}
    >
      <div className={classes.inner}>
        <Input
          className={classnames(classes.input, inputClassName)}
          classes={{ root: classes.input, underline: classes.underline }}
          type="email"
          error={hasError}
          placeholder="Your e-mail"
          inputProps={{
            maxLength: 100,
            autoCapitalize: 'off',
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'false',
            ref: inputRef,
            onFocus: inputOnFocus,
            onBlur: inputOnBlur,
            onChange: ({ target }) => setValue(target.value),
            value,
            ...inputProps,
          }}
        />

        <Button
          className={btnClassName}
          type="submit"
          color="secondary"
          disabled={value === ''}
        >
          {btnLabel}
        </Button>
      </div>

      {link && link.href && (
        <FormControlLabel
          className={classes.check}
          onChange={handleCheckbox}
          control={
            <Checkbox
              className={classes.checkbox}
              icon={<Svg.CheckboxOutline width="24" />}
              checkedIcon={<Svg.Checkbox width="24" />}
              color="default"
            />
          }
          label={checkboxLabel}
        />
      )}
      {hasError && <Typography className={classes.error}>{error}</Typography>}
    </form>
  )
}

Signup.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
  submitText: PropTypes.string,
  errorText: PropTypes.string,
  checkboxText: PropTypes.string,
  checkErrorText: PropTypes.string,
  fieldErrorText: PropTypes.string,
  link: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
  }),
}

Signup.defaultProps = {
  submitText: 'Sign up',
}

Signup.uiName = 'Signup'

export default withStyles(styles)(Signup)
