import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'
import Typography from 'components/Typography'
import Svg from 'components/Svg'

export const styles = theme => {
  return {
    root: {
      width: '100%',
      height: 'auto',
      minHeight: 58,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${theme.utils.spacing(5)} ${theme.utils.spacing(4)}`,
      background: theme.palette.grey[200],
      transform: 'translateY(-100%)',
      transition: 'transform linear 0.2s',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.utils.spacing(2)} ${theme.utils.spacing(3)}`,
      },
    },
    cookieText: {
      ...theme.typography.body1,
      color: theme.palette.secondary.main,
      textAlign: 'center',

      '& a': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    icon: {
      marginTop: theme.utils.spacing(2),
      cursor: 'pointer',
      '& svg': {
        fill: theme.palette.black,
      },

      [theme.breakpoints.up('md')]: {
        margin: `0 0 0 ${theme.utils.spacing(2)}`,
      },
    },
    hidden: {
      display: 'none',
    },
    slideUp: {
      transform: 'translateY(-100%)',
    },
    slideDown: {
      transform: 'translateY(0%)',
    },
  }
}

const CookieBar = props => {
  const { classes, className, text, ...other } = props
  const cookieBarEl = useRef()

  const isCookieSet = () => {
    if (typeof window !== 'undefined') {
      if (
        document.cookie.split(';').filter(item => item.includes('cicsCookie=1'))
          .length > 0
      ) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (!isCookieSet()) {
      setTimeout(
        () => cookieBarEl.current.classList.add(classes.slideDown),
        1500,
      )
    } else {
      cookieBarEl.current.classList.add(classes.hidden)
    }
  })

  const setCookieConfirmation = () => {
    cookieBarEl.current.classList.remove(classes.slideDown)
    cookieBarEl.current.classList.add(classes.slideUp)

    const date = new Date()
    const days = 365

    date.setTime(date + days * 86400000)

    window.document.cookie = `cicsCookie=1; expires=${date.toGMTString()}; path=/`
  }

  return (
    <div
      ref={cookieBarEl}
      className={classnames(classes.root, className)}
      {...other}
    >
      <Typography
        className={classes.cookieText}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <Svg.Close
        width="24"
        className={classes.icon}
        onClick={setCookieConfirmation}
      />
    </div>
  )
}

CookieBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
}

CookieBar.uiName = 'CookieBar'

export default withStyles(styles)(CookieBar)
