import React, { useContext } from 'react'
import AppContext from 'containers/AppContext'
import FooterComponent from 'components/Footer'

const Footer = () => {
  const {
    onSubscribe,
    settings: { footer },
  } = useContext(AppContext)

  return <FooterComponent onSubscribe={onSubscribe} {...footer} />
}

Footer.uiName = 'Footer'

export default Footer
