import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AppContext from 'containers/AppContext'
import AppBar from 'components/AppBar'
import Svg from 'components/Svg'
import Link from 'components/Link'
import withStyles from 'components/styles/withStyles'
import Typography from 'components/Typography'

export const styles = theme => ({
  root: {},
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: '0.24em',
    '& strong': {
      textTransform: 'uppercase',
    },
    '& p': {
      margin: 0,
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 10,
    },
  },
  icon: {
    cursor: 'pointer',
  },
})

const MobileToolbar = props => {
  const { classes, className, ...other } = props
  const { sidebarOpen, setSidebarOpen } = useContext(AppContext)
  const onClick = () => setSidebarOpen(!sidebarOpen)

  return (
    <AppBar
      className={classnames(classes.root, className)}
      {...other}
      color="inherit"
    >
      <div className={classes.grow}>
        <Link href="/" className={classes.logo}>
          <Svg.CICSLogoAnimated width="40" />

          <Typography
            className={classes.logoText}
            component="div"
            dangerouslySetInnerHTML={{
              __html: '<p>CI <strong>Model</strong></p>',
            }}
          />
        </Link>
      </div>
      <div className={classes.icon}>
        {sidebarOpen === false && <Svg.Burger width="24" onClick={onClick} />}
        {sidebarOpen && <Svg.Close width="24" onClick={onClick} />}
      </div>
    </AppBar>
  )
}

MobileToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

MobileToolbar.uiName = 'MobileToolbar'

export default withStyles(styles)(MobileToolbar)
