import { mapValues } from 'lodash'

export default class LocMemCache {
  static fromUrl() {
    return new LocMemCache()
  }

  constructor() {
    this.cache = {}
  }

  async get(key) {
    const entry = this.cache[key]
    if (!entry) {
      return undefined
    }
    const { value, expireAt } = entry
    if (expireAt && expireAt < new Date()) {
      return undefined
    }
    return value
  }

  async set(key, value, ttl = 300) {
    let expireAt = undefined
    if (ttl) {
      expireAt = new Date()
      expireAt.setSeconds(expireAt.getSeconds() + ttl)
    }
    this.cache[key] = { value, expireAt }
  }

  async load(data) {
    this.cache = mapValues(data, value => ({ ttl: 0, value }))
  }

  async dump() {
    return mapValues(this.cache, ({ value }) => value)
  }
}
