export default class DummyCache {
  static fromUrl() {
    return new DummyCache()
  }

  async get(_key) {
    return undefined
  }

  async set(_key, _value, _ttl = 0) {
    return Promise.resolve()
  }

  async load(_data) {
    return Promise.resolve()
  }

  async dump() {
    return {}
  }
}
