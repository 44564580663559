import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import withStyles from 'components/styles/withStyles'
import Link from 'components/Link'
import { Link as RouterLink } from 'routes'

export const styles = theme => ({
  root: {},
  active: {
    [theme.breakpoints.up('md')]: {
      color: `${theme.palette.primary.main} !important`,
    },
  },
})

const NavItem = props => {
  const { classes, className, href, router, title, ...other } = props

  let isActive = ''

  if (router && router !== 'undefined') {
    const { asPath: currentPath } = router
    isActive = currentPath === href
  }

  return (
    <Link
      className={classnames(className, { [classes.active]: isActive })}
      as={href}
      component={RouterLink}
      href="/blocks"
      {...other}
    >
      {title}
    </Link>
  )
}

NavItem.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  router: PropTypes.object,
  title: PropTypes.string.isRequired,
}

NavItem.uiName = 'NavItem'

export default withStyles(styles)(withRouter(NavItem))
