import PropTypes from 'prop-types'
import withStyles from '../styles/withStyles'
import BrandonLightWoff2 from '../../static/fonts/brandon_light.woff2'
import BrandonLightWoff from '../../static/fonts/brandon_light.woff'
import BrandonRegularWoff2 from '../../static/fonts/brandon_regular.woff2'
import BrandonRegularWoff from '../../static/fonts/brandon_regular.woff'
import BrandonMediumWoff2 from '../../static/fonts/brandon_medium.woff2'
import BrandonMediumWoff from '../../static/fonts/brandon_medium.woff'

const styles = theme => ({
  '@font-face': [
    {
      fontFamily: 'Brandon',
      fontWeight: 300,
      src: `
            url("${BrandonLightWoff2}") format("woff2"),
            url("${BrandonLightWoff}") format("woff")
        `,
    },
    {
      fontFamily: 'Brandon',
      fontWeight: 400,
      src: `
            url("${BrandonRegularWoff2}") format("woff2"),
            url("${BrandonRegularWoff}") format("woff")
        `,
    },
    {
      fontFamily: 'Brandon',
      fontWeight: 500,
      src: `
            url("${BrandonMediumWoff2}") format("woff2"),
            url("${BrandonMediumWoff}") format("woff")
        `,
    },
  ],
  '@global': {
    html: {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    body: {
      background: theme.palette.background.default,
      margin: 0,
    },
    'p a, li a': {
      color: 'inherit',
      borderBottom: `3px solid ${theme.palette.purple[500]}`,
      textDecoration: 'none',
      transition: 'border 0.1s linear',
      '&:hover': {
        borderBottomColor: theme.palette.accent.purple,
      },
    },
    // global style coming from Wordpress
    '.video-container': {
      position: 'relative',
      paddingBottom: '56.25%',
      paddingTop: 30,
      height: 0,
      overflow: 'hidden',
      margin: '56px 0',
      '& iframe': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
      },
    },
  },
})

const CssBaseline = props => {
  return props.children
}

CssBaseline.propTypes = {
  /**
   * You can wrap a node.
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  classes: PropTypes.object.isRequired,
}

CssBaseline.defaultProps = {
  children: null,
}

export default withStyles(styles)(CssBaseline)
