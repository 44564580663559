import { parse } from 'url'
import dummy from './dummy'
import locmem from './locmem'

const engineMapping = {
  dummy,
  locmem,
}

// Server-side only mappings
if (typeof window === 'undefined') {
  // eslint-disable-next-line no-undef
  engineMapping.redis = require('api/cache/redis').default
  // eslint-disable-next-line no-undef
  engineMapping.rediss = require('api/cache/redis').default
}

export default function loadEngine(scheme) {
  console.log(`Loading cache engine: "${scheme}".`)
  const url = parse(scheme)
  const engine = url.protocol.substr(0, url.protocol.length - 1)
  const module = engineMapping[engine]
  if (!module) {
    throw new Error(`No cache engine mapping for "${engine}" found.`)
  }
  return module.fromUrl(url)
}
