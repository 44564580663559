import querystring from 'querystring'
import getConfig from 'next/config'
import unfetch from 'isomorphic-unfetch'
import BaseClient from './baseClient'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

export default class WPClient extends BaseClient {
  constructor() {
    super()
    this.baseUrl = process.browser
      ? publicRuntimeConfig.WORDPRESS_URL
      : serverRuntimeConfig.WORDPRESS_URL
  }

  async request(method = 'GET', endpoint, params = '') {
    let url = `${this.baseUrl}/${endpoint}`

    if (method === 'GET' && params) {
      url += `?${querystring.stringify(params)}`
    }

    const start = Date.now()
    console.log(new Date().toLocaleTimeString(), 'WP: START - ', url)
    const response = await unfetch(url, {
      credentials: 'include',
      mode: 'cors',
      method,
      body:
        method === 'POST' && params !== '' ? JSON.stringify(params) : undefined,
    })
    console.log(
      new Date().toLocaleTimeString(),
      'WP: END',
      `${(Date.now() - start) / 1000}s`,
    )

    return response.json()
  }

  async getPage(slug, params = {}) {
    // return this.cachedRequest('GET', 'wp/v2/pages', { slug, ...params })
    return this.request('GET', 'wp/v2/pages', { slug, ...params })
  }

  async getPost(slug, params = {}) {
    return this.request('GET', 'wp/v2/posts', { slug, ...params })
  }

  async getPostsById(include, postType = 'posts', params = {}) {
    return this.request('GET', `wp/v2/${postType}`, { include, ...params })
  }

  async getSite(params = {}) {
    // return this.cachedRequest('GET', 'owc/v1/site', params)
    return this.request('GET', 'owc/v1/site', params)
  }
}
