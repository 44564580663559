import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from '../styles/withStyles'
import Typography from '../Typography'
import Signup from '../../containers/Signup'
import Sender from '../Sender'

export const styles = theme => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.blue[200],
    padding: `${theme.utils.spacing(9)} 20px ${theme.utils.spacing(12)}`,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: theme.utils.spacing(17),
      paddingRight: theme.utils.spacing(3),
      paddingBottom: theme.utils.spacing(20),
      paddingLeft: theme.utils.spacing(26),
      '& form button': {
        maxWidth: '50%',
      },
    },
  },
  signupContainer: {
    marginBottom: theme.utils.spacing(11),
    [theme.breakpoints.up('md')]: {
      width: 457,
      marginRight: theme.utils.spacing(22),
      marginTop: 38,
    },
  },
  senderContainer: {
    '& > *:first-child': {
      marginBottom: theme.utils.spacing(10),
    },
  },
  caption: {
    marginBottom: theme.utils.spacing(4),
  },
  desc: {
    marginBottom: theme.utils.spacing(3),
  },
})

const Footer = props => {
  const {
    classes,
    className,
    caption,
    errorText,
    checkErrorText,
    desc,
    submitText,
    checkboxText,
    fieldErrorText,
    onSubscribe,
    signup,
    senders,
    link,
    ...other
  } = props

  return (
    <div className={classnames(classes.root, className)} {...other}>
      <div className={classes.signupContainer}>
        <Typography variant="h2" className={classes.caption}>
          {caption}
        </Typography>
        <Typography variant="body1" className={classes.desc}>
          {desc}
        </Typography>
        <Signup
          submitText={submitText}
          onSubmit={onSubscribe}
          fieldErrorText={fieldErrorText}
          link={link}
          checkboxText={checkboxText}
          errorText={errorText}
          checkErrorText={checkErrorText}
        />
      </div>
      <div className={classes.senderContainer}>
        {senders.map((sender, index) => (
          <Sender
            key={`FooterSender-${index}`}
            image={sender.image}
            caption={sender.caption}
            label={sender.label}
            link={sender.link}
          />
        ))}
      </div>
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  caption: PropTypes.string,
  checkboxText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  checkErrorText: PropTypes.string.isRequired,
  desc: PropTypes.string,
  link: PropTypes.object.isRequired,
  submitText: PropTypes.string,
  onSubscribe: PropTypes.func.isRequired,
  senders: PropTypes.array,
  signup: PropTypes.object,
  fieldErrorText: PropTypes.string,
}

Footer.uiName = 'Footer'

export default withStyles(styles)(Footer)
