import React from 'react'
import App, { Container } from 'next/app'
import Head from 'next/head'
import {
  MuiThemeProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'
import { SheetsRegistry } from 'react-jss'
import JssProvider from 'react-jss/lib/JssProvider'
import CssBaseline from 'components/CssBaseline'
import WPClient from 'api/wordpress'
import createCustomTheme from 'components/styles/createCustomTheme'
import { AppContextProvider } from 'containers/AppContext'
import Page from 'containers/Page'

const globalWPClient =
  typeof window !== 'undefined' ? new WPClient() : undefined

function createPageContext() {
  return {
    theme: createCustomTheme(),
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  }
}

function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (typeof window === 'undefined') {
    return createPageContext()
  }

  // Reuse context on the client-side.
  if (!window.__INIT_MATERIAL_UI__) {
    window.__INIT_MATERIAL_UI__ = createPageContext()
  }

  return window.__INIT_MATERIAL_UI__
}

export default class MyApp extends App {
  pageContext = getPageContext()

  static async getInitialProps({ Component, ctx }) {
    const wpClient = globalWPClient || new WPClient()
    const isServer = typeof ctx.req !== 'undefined'

    let appData = {}
    // Only do this on the server
    if (isServer) {
      try {
        const site = await wpClient.getSite()

        appData = {
          mainMenu: site && site.menus && site.menus.mainMenu,
          frontpage: site && site.frontpage,
          settings: site && site.settings,
        }
      } catch (error) {
        // console.log('error', error)
      }
    }

    const pageProps =
      Component.getInitialProps == null
        ? undefined
        : await Component.getInitialProps(ctx, {
            appData,
            wpClient,
          })

    return {
      pageProps,
      appData: {
        isServer,
        ...appData,
      },
    }
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  render() {
    const { Component, appData, pageProps } = this.props
    return (
      <Container>
        <Head>
          <title>My page</title>
        </Head>
        {/* Wrap every page in Jss and Theme providers */}
        <JssProvider
          registry={this.pageContext.sheetsRegistry}
          generateClassName={this.pageContext.generateClassName}
        >
          {/* MuiThemeProvider makes the theme available down the React
              tree thanks to React context. */}
          <MuiThemeProvider
            theme={this.pageContext.theme}
            sheetsManager={this.pageContext.sheetsManager}
          >
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {/* Pass pageContext to the _document though the renderPage enhancer
                to render collected styles on server side. */}
            <AppContextProvider {...appData}>
              <Page>
                <Component pageContext={this.pageContext} {...pageProps} />
              </Page>
            </AppContextProvider>
          </MuiThemeProvider>
        </JssProvider>
      </Container>
    )
  }
}
