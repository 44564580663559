export const grey = {
  200: '#EBEBEB',
  300: '#CCCCCC',
  400: '#999999',
  500: '#333333',
  600: '#1C1C1C',
  700: '#000000',
}

export const purple = {
  200: '#EEE9FD',
  300: '#E0D8F7',
  400: '#D0C2F9',
  500: '#A385FB',
  600: '#7457C0',
  700: '#493781',
}

export const accent = {
  purple: '#9B51E0',
  pink: '#F266BB',
  turquoise: '#00CDFF',
  blue: '#286EEF',
  green: '#63FD93',
}

export const blue = {
  200: '#EAF0FB',
  300: '#C7DAFC',
  400: '#90B7FF',
  500: '#679BFA',
  600: '#4874C3',
  700: '#2E569F',
}

export const turquoise = {
  200: '#AFECFB',
  300: '#98D9EC',
  400: '#6DC4DE',
  500: '#27BBE5',
  600: '#27A1C4',
  700: '#18819F',
}

export const common = {
  black: '#000',
  white: '#fff',
}
