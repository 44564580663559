import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from '../styles/withStyles'
import Typography from '../Typography'
import Button from '../Button'
import Link from 'components/Link'

export const styles = theme => ({
  root: {
    maxWidth: 220,
  },
  img: {
    maxHeight: 129,
    width: 'auto',
    maxWidth: '100%',
    objectFit: 'contain',
    marginBottom: theme.utils.spacing(5),
  },
  caption: {
    ...theme.typography.body1,
    marginBottom: theme.utils.spacing(3),
  },
})

const Sender = props => {
  const {
    children,
    classes,
    className,
    label,
    image,
    caption,
    link,
    ...other
  } = props

  return (
    <div className={classnames(classes.root, className)} {...other}>
      {image && <img src={image} className={classes.img} />}
      <Typography className={classes.caption}>{caption}</Typography>
      <Button color="primary" href={link} component={Link}>
        {label}
      </Button>
    </div>
  )
}

Sender.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  caption: PropTypes.string,
}

Sender.uiName = 'Sender'

export default withStyles(styles)(Sender)
