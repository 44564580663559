import React, { useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import MobileToolbar from '../MobileToolbar'
import AppContext from 'containers/AppContext'
import NavigationItem from 'containers/Page/NavigationItem'
import withStyles from 'components/styles/withStyles'
import Svg from 'components/Svg'

export const styles = theme => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  bgImage: {
    position: 'absolute',
    width: '178%',
    left: -46,
    top: '71%',
    bottom: '50%',
    transform: 'translateY(-50%)',
    zIndex: -1,
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    right: 30,
    top: 30,
  },
  navItem: {
    ...theme.typography.h3,
    minHeight: 70,
    width: '100%',
    color: theme.palette.black,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: `0 ${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 7}px`,
    textTransform: 'uppercase',
    '&:first-of-type': {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
})

const Sidebar = props => {
  const { classes } = props
  const { mainMenu, sidebarOpen, setSidebarOpen } = useContext(AppContext)
  const onClick = () => setSidebarOpen(false)

  return (
    <Drawer
      anchor="right"
      open={sidebarOpen}
      onClose={() => setSidebarOpen(false)}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <MobileToolbar />
      <Svg.CICSLogoAnimated className={classes.bgImage} />

      {mainMenu.map(item => (
        <Grid
          key={`sidebarNavItem-${item.id}`}
          className={classes.navItem}
          container
          alignItems="center"
          justify="space-between"
          onClick={onClick}
        >
          <NavigationItem {...item} item />
          <Svg.ArrowRight width="24" item />
        </Grid>
      ))}
    </Drawer>
  )
}
Sidebar.uiName = 'Sidebar'

Sidebar.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Sidebar)
