import querystring from 'querystring'
import getConfig from 'next/config'
import LocMemCache from 'api/cache/locmem'
import loadEngine from 'api/cache'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

const CACHE_BACKEND = serverRuntimeConfig.CACHE || publicRuntimeConfig.CACHE

export const cacheBackend = loadEngine(CACHE_BACKEND)

/**
 * Ensures a consistent insertion order.
 */
export function sorted(o) {
  const p = Object.create(null)
  Object.keys(o)
    .sort()
    .forEach(k => (p[k] = o[k]))
  return p
}

// This class is meant to be used during SSR in order to intercept cache store
// operations. The intercept cached entries can be then sent over to the client
// so it can prepopulate its own cache with them. This saves a lot of
// unnecessary API calls. The reason for this being a proxy is that it can be
// combined with any cache backend.
const createCacheMirror = (backend, store) =>
  new Proxy(backend, {
    get(target, prop, receiver) {
      const f = Reflect.get(target, prop, receiver)

      // Since we are only interested in things that are being cached, we
      // intercept all the `set` and `get` calls to the chache backend.
      if (prop === 'get') {
        return async function(key, ...args) {
          // eslint-disable-next-line babel/no-invalid-this
          const response = await f.apply(this, [key, ...args])
          if (response) {
            store.set(key, response, undefined)
          }
          return response
        }
      }
      if (prop === 'set') {
        return function(key, value, ...args) {
          store.set(key, value, undefined)
          // eslint-disable-next-line babel/no-invalid-this
          return f.apply(this, [key, value, ...args])
        }
      }
      return f
    },
  })

export default class BaseClient {
  constructor() {
    this.requestCache = new LocMemCache()

    // TODO: If we are rendering on the server, the regualr cache backend should
    // be wrapped in an adapter that stores the data that got cached during the
    // request. This way we can use any backend while keeping posibillity to
    // reuse the cache generated on the server by the client.
    this.cache =
      typeof window === 'undefined'
        ? createCacheMirror(cacheBackend, this.requestCache)
        : cacheBackend
  }

  getCache() {
    return this.cache
  }

  toJSON() {
    // As this object is sent around using props and the shared context,
    // we want to prevent it from being serialized and sent to the client
    // as it could possibly include some sensitive data (for example the
    // internal API host).
    return null
  }

  async cachedRequest(method = 'GET', endpoint, data) {
    const dataKey = querystring.stringify(sorted(data || {}))
    const key = `${method} ${this.baseUrl} ${endpoint} ${dataKey}`
    let response = await this.cache.get(key)
    if (!response) {
      console.log(
        new Date().toLocaleTimeString(),
        `BaseClient: Cache miss for`,
        key,
      )
      response = await this.request(method, endpoint, data)
      await this.cache.set(key, response)
    } else {
      console.log(
        new Date().toLocaleTimeString(),
        `BaseClient: Cache hit for`,
        key,
      )
    }
    return response
  }

  async clientCachedRequest(method = 'GET', endpoint, data) {
    // When doing a client cached request on the server, we want to make sure to
    // propagate the fetched data back to the client, but not persist it in the
    // server side cache.
    if (typeof window === 'undefined') {
      const dataKey = querystring.stringify(sorted(data || {}))
      const key = `${method} ${this.baseUrl} ${endpoint} ${dataKey}`
      const response = await this.request(method, endpoint, data)
      await this.requestCache.set(key, response)
      return response
    }
    return this.cachedRequest(method, endpoint, data)
  }
}
