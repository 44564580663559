import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import withStyles from '@material-ui/core/styles/withStyles'

export const styles = theme => ({
  root: {
    boxShadow: 'none',
  },
  toolbar: {
    minHeight: 70,
    [theme.breakpoints.up('md')]: {
      minHeight: 140,
    },
  },
})

const AppBar = props => {
  const { children, classes, className, ...other } = props

  return (
    <MuiAppBar
      className={classnames(classes.root, className)}
      position="sticky"
      {...other}
    >
      <Toolbar className={classes.toolbar}>{children}</Toolbar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

AppBar.uiName = 'AppBar'

export default withStyles(styles)(AppBar)
